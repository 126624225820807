var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    {
      staticClass: "iq-input-form",
      staticStyle: { width: "100%" },
      style: { ..._vm.ownstyle, inputWidth: _vm.inputWidth },
      attrs: {
        label: _vm.label,
        prop: _vm.prop,
        rules: _vm.rules,
        "label-width": _vm.labelWidth,
      },
    },
    [
      _c("el-input", {
        class: { fullWidth: _vm.fullWidth, ownclass: _vm.ownclass },
        style: { width: _vm.inputWidth },
        attrs: {
          type: _vm.type,
          disabled: _vm.disabled,
          placeholder: _vm.placeholder,
          "fetch-suggestions": _vm.options,
          "show-password": _vm.showPassword,
          clearable: _vm.isClearable,
          inputmode: _vm.inputmode,
        },
        on: { input: _vm.handleInput, focus: _vm.onFocus },
        model: {
          value: _vm.inputVal,
          callback: function ($$v) {
            _vm.inputVal = $$v
          },
          expression: "inputVal",
        },
      }),
      _vm.message.length > 0
        ? _c("span", { staticClass: "info-message" }, [
            _vm._v(" " + _vm._s(_vm.message) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }