const countValidator = (rule, value, callback) => {
  if (Number(value) <= 0) {
    callback(new Error('Не бывает нулевых квот!'))
  } else {
    callback()
  }
}

export const ruleAddQuota = {
  time_from: {
    required: true,
    message: 'Заполните время',
    trigger: 'blur',
  },
  time_to: {
    required: true,
    message: 'Заполните время',
    trigger: 'blur',
  },
  count: [
    {
      required: true,
      message: 'Укажите количество авто в час',
      trigger: 'blur',
    },
    {
      validator: countValidator,
      trigger: 'blur',
    },
  ],
  totalCount: [
    {
      required: true,
      message: 'Заполните ожидаемое количество машин',
      trigger: 'blur',
    },
    {
      validator: countValidator,
      trigger: 'blur',
    },
  ],
  truck_type: {
    type: 'array',
    required: true,
    message: 'Выберите тип автомобилей',
    trigger: 'blur',
  },
  cultures_ids: {
    required: true,
    message: 'Выберите тип культуры',
    trigger: 'blur',
  },
  exporter_id: {
    required: true,
    message: 'Выберите экспортёра',
    trigger: 'change',
  },
}
