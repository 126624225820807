var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    {
      staticClass: "iq-select-form",
      style: _vm.cssVars,
      attrs: {
        label: _vm.label,
        prop: _vm.prop,
        "label-width": _vm.labelWidth,
      },
    },
    [
      _c(
        "el-select",
        {
          class: { fullWidth: _vm.fullWidth },
          style: { width: _vm.inputWidth },
          attrs: {
            disabled: _vm.disabled,
            multiple: _vm.isMultiple,
            filterable: _vm.isFilterable,
            "allow-create": "",
            "default-first-option": _vm.defaultFirstOption,
            placeholder: _vm.placeholder,
          },
          model: {
            value: _vm.inputVal,
            callback: function ($$v) {
              _vm.inputVal = $$v
            },
            expression: "inputVal",
          },
        },
        _vm._l(_vm.options, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item[_vm.keyLabel], value: item[_vm.keyValue] },
          })
        }),
        1
      ),
      _vm.disabled
        ? _c("span", { staticClass: "info-message" }, [
            _vm._v(" " + _vm._s(_vm.message) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }