<template>
  <el-form-item
    :label="label"
    :prop="prop"
    :rules="rules"
    :label-width="labelWidth"
    :style="{ ...ownstyle, inputWidth }"
    style="width: 100%"
    class="iq-input-form"
  >
    <el-input
      v-model="inputVal"
      :type="type"
      :class="{ fullWidth, ownclass }"
      :disabled="disabled"
      :style="{ width: inputWidth }"
      :placeholder="placeholder"
      :fetch-suggestions="options"
      :show-password="showPassword"
      :clearable="isClearable"
      :inputmode="inputmode"
      @input="handleInput"
      @focus="onFocus"
    />
    <span v-if="message.length > 0" class="info-message">
      {{ message }}
    </span>
  </el-form-item>
</template>

<script>
export default {
  name: 'IqInputForm',
  props: {
    ownstyle: {
      type: String,
      default: '',
    },
    inputmode: {
      type: String,
      default: 'text',
    },
    ownclass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isClearable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      require: true,
    },
    label: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'input',
    },
    prop: {
      type: String,
      default: '',
    },
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: [Array, Object],
      default: undefined,
    },
    labelWidth: {
      type: String,
      default: '',
    },
    inputWidth: {
      type: String,
      default: 'auto',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    showPassword: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('onInput', event)
    },
    onFocus() {
      this.$emit('on-focus')
    },
  },
}
</script>

<style lang="sass">
.iq-input-form
  &.el-form-item
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 12px!important
    .el-form-item__label
      font-family: Roboto, sans-serif
      font-style: normal
      font-weight: normal
      font-size: 14px
      color: #606266
      line-height: 14px!important
      text-align: left
    .el-form-item__label:before
      content: '' !important
    .el-form-item__content
      margin-left: 0 !important
      flex-grow: 1
      width: 100%
    .el-form-item__error
      position: absolute
      display: flex
.info-message
  font-size: 11px
  color: $btn-orange!important

@media (max-width: 1199px)
  .iq-input-form
    &.el-form-item
      flex-direction: column
      align-items: flex-start
      row-gap: 5px
      width: 100%
      &__content
        width: 100%
</style>
