<template>
  <el-form-item
    class="iq-select-form"
    :label="label"
    :prop="prop"
    :label-width="labelWidth"
    :style="cssVars"
  >
    <el-select
      v-model="inputVal"
      :disabled="disabled"
      :multiple="isMultiple"
      :filterable="isFilterable"
      :class="{ fullWidth }"
      allow-create
      :default-first-option="defaultFirstOption"
      :placeholder="placeholder"
      :style="{ width: inputWidth }"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item[keyLabel]"
        :value="item[keyValue]"
      />
    </el-select>
    <span v-if="disabled" class="info-message">
      {{ message }}
    </span>
  </el-form-item>
</template>

<script>
export default {
  name: 'IqSelectForm',
  props: {
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isFilterable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultFirstOption: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      require: true,
    },
    label: {
      type: String,
    },
    prop: {
      type: String,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
    },
    placeholder: {
      type: String,
    },
    message: {
      type: String,
    },
    keyLabel: {
      type: String,
      default: 'name',
    },
    keyValue: {
      type: String,
      default: 'id',
    },
    labelWidth: {
      type: String,
      default: '',
    },
    inputWidth: {
      type: String,
      default: 'auto',
    },
    isSuperImportantLabelWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    cssVars() {
      return {
        '--label-width': this.isSuperImportantLabelWidth
          ? this.labelWidth
          : '100%',
      }
    },
  },
  mounted() {
    document
      .querySelectorAll('.el-select .el-input input')
      .forEach(e => (e.readOnly = true))
  },
}
</script>

<style lang="sass">
$color-blue-primary: #4CA7D8

.iq-select-form
  width: 100%
  .info-message
    font-size: 11px
    color: #DB6D39!important
  .el-form-item__label
    font-family: Roboto, sans-serif
    font-weight: 400 !important
    font-size: 14px !important
    line-height: 22px
    color: #606266
  .el-form-item__label:before
    content: '' !important
  .el-select-dropdown
    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.1))
    border: none
    box-shadow: none
  .el-select-dropdown__item.selected
    font-family: Roboto, sans-serif
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 22px
    display: flex
    align-items: center
    font-feature-settings: 'tnum' on, 'lnum' on
    color: $color-blue-primary
  &.el-form-item
    margin-bottom: 12px!important
    .el-icon-arrow-up:before
      content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.75 22.625L12.5 16.375L6.25 22.625' stroke='%23C0C4CC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important
    .el-form-item__label:before
      content: '' !important
</style>
